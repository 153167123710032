.exercise-database {
    .search-bar {
        margin-bottom: 25px;

        .input-group {
            width: 30%;

            @media screen and (max-width: 992px) {
                & {
                    width: 50%;
                }
            }

            @media screen and (max-width: 768px) {
                & {
                    width: 80%;
                }
            }

            @media screen and (max-width: 514px) {
                & {
                    width: 70%;
                }
            }
        }
        .btn{
            border: 1px solid lightgrey;
            background-color: white;
        }
    }

    .exercise {
        height: 100%;
        border: none;
        border-radius: 23px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);

        .position-absolute {
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
        }

        .card-img-top {
            padding: 10px 10px 0 10px;
            border-radius: 30px;
        }

        .data {
            display: flex;
            flex-direction: column;
            gap: 15px;

            & div {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 10px;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 160%;
            }

            .badge {
                /*padding: 8px;*/
                background-color: #F36F27 !important;
            }
        }
    }

    .btn-more {
        border: none;
        background-color: #F36F27;
    }
}

.exercise-database-modal {
    .exercise {
        .video {
            .video-react-big-play-button {
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                width: auto;
                padding: 22px 30px;
            }
        }

        .data {
            display: flex;
            flex-direction: column;
            gap: 15px;
            border-radius: 16px;
            background: #F6F6F6;

            & div {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 10px;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: 160%; /* 19.2px */
            }

            .badge {
                /*padding: 8px;*/
                background-color: #F36F27 !important;
            }
        }
    }
}

.exercise-filter-modal {
    .filter {
        .form {
            display: flex;
            flex-direction: column;
            gap: 15px;
            color: #111827;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
        }

        .button {
            padding: 10px;
            border-radius: 10px;
            background-color: #F36F27 !important;
            border-color: #F36F27;
        }
    }
}
