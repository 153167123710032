@import url('https://fonts.googleapis.com/css2?family=Poppins:display=swap');

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-BlackItalic.eot');
    src: url('../src/assets/font/Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-BlackItalic.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-BlackItalic.woff') format('woff'),
    url('../src/assets/font/Gilroy-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-Bold.eot');
    src: url('../src/assets/font/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-Bold.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-Bold.woff') format('woff'),
    url('../src/assets/font/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-Black.eot');
    src: url('../src/assets/font/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-Black.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-Black.woff') format('woff'),
    url('../src/assets/font/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-ExtraBold.eot');
    src: url('../src/assets/font/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-ExtraBold.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-ExtraBold.woff') format('woff'),
    url('../src/assets/font/Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-Light.eot');
    src: url('../src/assets/font/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-Light.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-Light.woff') format('woff'),
    url('../src/assets/font/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-ExtraBoldItalic.eot');
    src: url('../src/assets/font/Gilroy-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-ExtraBoldItalic.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-ExtraBoldItalic.woff') format('woff'),
    url('../src/assets/font/Gilroy-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-Heavy.eot');
    src: url('../src/assets/font/Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-Heavy.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-Heavy.woff') format('woff'),
    url('../src/assets/font/Gilroy-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-BoldItalic.eot');
    src: url('../src/assets/font/Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-BoldItalic.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-BoldItalic.woff') format('woff'),
    url('../src/assets/font/Gilroy-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-HeavyItalic.eot');
    src: url('../src/assets/font/Gilroy-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-HeavyItalic.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-HeavyItalic.woff') format('woff'),
    url('../src/assets/font/Gilroy-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-Medium.eot');
    src: url('../src/assets/font/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-Medium.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-Medium.woff') format('woff'),
    url('../src/assets/font/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-MediumItalic.eot');
    src: url('../src/assets/font/Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-MediumItalic.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-MediumItalic.woff') format('woff'),
    url('../src/assets/font/Gilroy-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-LightItalic.eot');
    src: url('../src/assets/font/Gilroy-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-LightItalic.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-LightItalic.woff') format('woff'),
    url('../src/assets/font/Gilroy-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-SemiBoldItalic.eot');
    src: url('../src/assets/font/Gilroy-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-SemiBoldItalic.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-SemiBoldItalic.woff') format('woff'),
    url('../src/assets/font/Gilroy-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-Regular.eot');
    src: url('../src/assets/font/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-Regular.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-Regular.woff') format('woff'),
    url('../src/assets/font/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-SemiBold.eot');
    src: url('../src/assets/font/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-SemiBold.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-SemiBold.woff') format('woff'),
    url('../src/assets/font/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-ThinItalic.eot');
    src: url('../src/assets/font/Gilroy-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-ThinItalic.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-ThinItalic.woff') format('woff'),
    url('../src/assets/font/Gilroy-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-UltraLightItalic.eot');
    src: url('../src/assets/font/Gilroy-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-UltraLightItalic.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-UltraLightItalic.woff') format('woff'),
    url('../src/assets/font/Gilroy-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy-RegularItalic';
    src: url('../src/assets/font/Gilroy-RegularItalic.eot');
    src: url('../src/assets/font/Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-RegularItalic.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-RegularItalic.woff') format('woff'),
    url('../src/assets/font/Gilroy-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-UltraLight.eot');
    src: url('../src/assets/font/Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-UltraLight.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-UltraLight.woff') format('woff'),
    url('../src/assets/font/Gilroy-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../src/assets/font/Gilroy-Thin.eot');
    src: url('../src/assets/font/Gilroy-Thin.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/font/Gilroy-Thin.woff2') format('woff2'),
    url('../src/assets/font/Gilroy-Thin.woff') format('woff'),
    url('../src/assets/font/Gilroy-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', 'Gilroy', serif;
    height: 100%;
}

hr {
    border-color: #f1f1f1;
    opacity: 1;
}

::-webkit-scrollbar {
    width: 4px;
    /* background: #bbbbbb; */
}

.text-green {
    color: #F36F27 !important;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.bg-green {
    background: #F36F27 !important;
}

.bg-lightgreen {
    background: #E6EFDA !important;
}

.pink {
    background: #FF5959;
}

.green {
    background: #87C531;
}

.purple {
    background: #5C63FF;
}

.orange {
    background: #FF922E;
}

.custom-border {
    border: 2px solid #F36F27 !important;

}

.rounded-32 {
    border-radius: 32px !important;
}

.bg-none {
    background-color: #81c52200 !important;
}

.bg-grey {
    background: #f1f1f1 !important;
}

.text-custom-grey {
    color: #6D6D6D !important;
}

.custom-shadow {
    box-shadow: 0px 3px 11px 0px #f36e2753 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.Raleway {
    font-family: 'Raleway', sans-serif;
}

.Inter {
    font-family: 'Inter', sans-serif;
}

.login.container-fluid {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.login.container-fluid .row {
    height: 100%;
    flex-grow: 1;
    /* Expand to fill remaining height */
}

.login.container-fluid .row .login-right {
    background: #F36F27;
}

.login.container-fluid .row .login-right img {
    width: 35vw;
}

.login-grid {
    display: grid;
    grid-template-columns: 45% 55%;
    /*height: 100vh;*/
    /*background: url(../src/assets/images/login-background.png);*/
    /*background-repeat: no-repeat;*/
    /*background-size: 100% 100%;*/
    /*background-position: center;*/
}

.login-logo {
    /*background: url(../src/assets/images/login-left-bg.png);*/
    /*background-repeat: no-repeat;*/
    /*background-position: center;*/
    display: grid;
    align-items: center;
    justify-content: center;
}

.login .login-left h2 {
    font-size: 45px;
}

.login .login-left p {
    font-size: 25px;
}

.login .login-left label {
    font-size: 18px;
    color: #343434;
}

.login .login-left input {
    font-size: 18px;
    background-color: #FBFBFB;
    border: 1px solid #D6D6D6;
    height: 61px;
    font-weight: 600;
}

.login .login-left input:focus {
    outline: 0;
    box-shadow: 1px 1px 5px 3px rgba(243, 111, 39, 0.5);
}

.login .login-left .password-toggle {
    padding: 0 20px;
    border-color: #F36F27;
}

.login .login-left .password-toggle svg {
    fill: #F36F27;
}

.login .login-left .password-toggle:hover svg {
    fill: #FFFFFF;
}

.login .login-left .password-toggle:hover {
    color: red;
    background-color: #F36F27;
}

.login-welcome h2 {
    font-size: 45px;
}

.login-welcome p {
    font-size: 30px;
}

.login-welcome label {
    font-size: 18px;
    color: #343434;
}

.login-welcome input {
    font-size: 18px;
    background-color: #FBFBFB;
    border: 1px solid #D6D6D6;
    height: 61px;
    color: #A9A9A9;
    font-weight: 400;
}

a.toggle {
    position: relative;
    z-index: 100;
    margin-top: 20px;
}

.dashboard-layout {
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
}

/*.sidebar {*/
/*    position: fixed;*/
/*    width: 0;*/
/*    left: -100%;*/
/*    top: 0;*/
/*    z-index: 99;*/
/*    transition: .7s ease-out !important;*/
/*}*/

.sidebar-active {
    left: 0;
    min-width: 270px;
    transition: .7s ease-in;
}

.dashboard-content {
    width: calc(100% - 125px);
    transition: .7s ease-in;
    margin-left: auto;
}

@media screen and (max-width: 768px) {
    .dashboard-content {
        width: 100% !important;
    }
}

.dash-active {
    width: calc(100% - 300px);
}

.user-img {
    width: 80px;
    height: 80px;
    margin: auto;
    background: #e1e1e1;
    border-radius: 50%;
}

.user-img img {
    border-radius: 50%;
}

.sidebar-link a {
    padding: 10px 20px;
    text-decoration: none;
    font-size: 18px;
    display: block;
    width: 100%;
    font-weight: 500;
    font-family: 'Gilroy';
    color: #000;
    border-radius: 5px;
}

.sidebar-link a svg {
    margin-right: 15px;
    fill: #F36F27;

}

.sidebar-link a:hover {
    background: #F36F27;
    color: #fff;
}

.sidebar-link a.active {
    background: #F36F27;
    color: #fff;
}

.sidebar-link a.active svg,
.sidebar-link a:hover svg {
    fill: #fff;
}

.badge-icon a {
    position: relative;
}

.badge-icon a .badge {
    position: absolute;
    top: -15px;
    right: -15px;
    background: #FF5959 !important;
    border-radius: 50%;
    border: 2px solid #fff;
}

.dashboard-content {
    background: #f9f9f9;
}

.icon-bg {
    width: 48px;
    height: 48px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yellow-bg {
    background: #FFFAEB;
}

.green-bg {
    background: #F3FFF7;
}

.red-bg {
    background: #FFF9F9;
}

.purple-bg {
    background: #FAF9FF;
}

.blue-bg {
    background: #F6FDFF;
}

.parrot-bg {
    background: #F8FFF2;
}

.img-salad {
    width: 61px;
    height: 61px;
    background: #D9D9D9;
    border-radius: 15px;
    /* margin-right: 20px; */
}

.img-salad img {
    border-radius: 14px;
    object-fit: cover;
}

.left-border {
    border-left: 2px solid #F36F27;
    text-indent: 10px;
}

.main-content {
    height: 90vh;
    overflow-y: auto;
    width: 100%;
}

.progress-grid {
    display: grid;
    grid-template-columns: 5% 60% 10% 10%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.profile-img {
    border-radius: 9px;
    background: #EEEEDC;
    width: 187px;
    height: 187px;
    margin: auto;
}

.profile-img img {
    object-fit: cover;
    border-radius: 9px;
}

.profile-form label {
    color: #7D7C7C;
    /* font-family: Gilroy-Medium; */
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.profile-form input {
    border: 1px solid #D6D6D6;
    background: #FBFBFB;
    height: 63px;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
}

.profile-form textarea {
    border: 1px solid #D6D6D6;
    background: #FBFBFB;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: normal;
}

.wrapper input[type=file],
.wrapper-2 input[type=file] {
    height: 0;
    overflow: hidden;
    width: 0;
    opacity: 0;
}

.wrapper input[type=file] + label {
    border-radius: 7px;
    background: linear-gradient(142deg, #8DC63F 0%, #7FC520 100%);
    box-shadow: 0px 3px 11px 0px rgba(144, 200, 68, 0.30);
    border: none;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 2rem;
    outline: none;
    padding: 10px 15px;
    position: relative;
    transition: all 0.3s;
}

.wrapper-2 input[type=file] + label {
    background: none;
    border: none;
    color: #000000;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    padding: 5px;
}

.user-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 100px;
}

.green-dot {
    display: block;
    width: 15px;
    border-radius: 50%;
    height: 15px;
    margin-left: auto;
    background: #83C529;
    margin-top: -9px;
    position: relative;
    border: 2px solid #fff;
}

.pink-dot {
    display: block;
    width: 15px;
    border-radius: 50%;
    height: 15px;
    margin-left: auto;
    background: #F46870;
    margin-top: -9px;
    position: relative;
    border: 2px solid #fff;
}

.text-pink {
    color: #F46870;
}

.profile-img-2 {
    width: 140px;
    height: 140px;
    margin: auto;
    border-radius: 30px;
}

.profile-img-2 img {
    object-fit: cover;
    border-radius: 30px;
}

.green-line {
    display: inline-block;
    width: 50px;
    margin: 10px auto;
    height: 3px;
    border-radius: 50px;
    text-align: center;
    background: #8dc63f;
}

.profile-info {
    display: grid;
    grid-template-columns: 30% 70%;
    row-gap: 20px;
    margin: 30px auto;
}

.profile-tab .nav-tabs {
    --bs-nav-tabs-border-width: 0px;
    border-bottom: 1px solid #f1f1f1;
    padding: 0 30px;
}

.profile-tab .nav-tabs .nav-link {
    color: #B5B5B5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.profile-tab .nav-tabs .nav-link.active {
    color: #F36F27;
    border-bottom: 2px solid #F36F27;
}

.membership-box {
    border-radius: 24px;
    border: 1px solid #F36F27;
    background: #FCFCFC;
}

.custom-hr {
    margin-top: 20px;
    display: inline-block;
    position: relative;
    width: 100%;
    height: 1px;
    background: #f1f1f1;
}

.custom-hr::before {
    position: absolute;
    content: '';
    width: 40%;
    height: 3px;
    top: -1px;
    background: #F36F27;
}

.h-70vh {
    height: 70vh !important;
}

.h-75vh {
    height: 100% !important;
}

.message-img {
    width: 43px;
    height: 43px;
    border-radius: 10px;
}

.message-img img {
    border-radius: 10px;
    object-fit: cover;
}

.msg {
    width: fit-content;
    padding: 9px 30px;
    font-size: 17px;
    font-weight: 600;
    color: #000;
    display: block;
    max-width: 400px;
    /* min-width: 228px; */
    margin-bottom: 5px;
    border-radius: 32px 32px 32px 0;
}

.msg-left {
    display: flex;
    justify-content: start;
}

.msg-right {
    display: flex;
    justify-content: end;
}

.msg-right .msg {
    border-radius: 32px 32px 0 32px;
}

.msg-list {
    height: 58vh;
    overflow-y: auto;
}

.msg-type {
    display: grid;
    grid-template-columns: 5% 80% 13%;
    justify-content: space-between;
}

.msg-type-2 {
    display: grid;
    grid-template-columns:93% 7%;
    justify-content: space-between;
}

.msg-right .user-msg.msg {
    border-radius: 10px 0px 10px 10px;
}

.user-msg.msg {
    border-radius: 0px 10px 10px 10px;
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 25px;
}

.submenu-2 a,
.submenu a {
    display: block;
}

.submenu-2,
.submenu {
    /* padding-left: 20px; */
    display: block;
    margin-left: 20px;
}

/* .submenu-2.show_menu2,
.submenu.show_menu {
    display: block;
    width: 100%;
} */
.sidebar-area {
    background: #fff;
    height: 68vh;
    margin-top: 40px;

}

.sidebar-link {
    height: 67vh;
    overflow-y: auto;
}

#workout,
#nutrition {
    background: none !important;
    color: #000;
}

#workout svg,
#nutrition svg {
    fill: #F36F27;
}

.faq-container .accordion-button {
    color: #000;
    font-size: 18px;
    background: #fff !important;
    font-style: normal;
    border: 1px solid #fff;
    font-weight: 600;
    border-radius: 8px !important;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05) !important;
}

.faq-container .accordion-item {
    border: 0px !important;
    margin-bottom: 20px;
}

.faq-container .accordion-button:not(.collapsed) {
    border: 1px solid #F36F27;
}

.recipe-card-img {
    /* width: 371px; */
    height: 254px;
}

.recipe-card-img img {
    border-radius: 13px 13px 0 0;
}

.recipe-grid {
    grid-gap: 25px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
}

.user-recipe-box .recipe-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.recipe-box {
    word-wrap: break-word;
    background: #fff;
    border-radius: 13px;
    box-shadow: 0 7px 10px 0 #00000005;
    display: flex;
    flex-direction: column;
    position: relative;
}


.recipe-card-body {
    padding: 15px 0 0 15px;
    flex: 1 0;

}

.recipe-card-footer {
    text-align: center;
    margin-bottom: 15px;
}

.view-recipe-img {
    height: 365px;
}

.view-recipe-img img {
    border-radius: 13px 13px 0 0;
}

.recipe-card-img iframe {
    border-radius: 13px 13px 0 0;
}

.form-range::-webkit-slider-thumb {

    background: #F36F27;

}

.form-range::-moz-range-thumb {
    background: #F36F27;
}

.form-range::-ms-thumb {
    background: #F36F27;
}


.form-range::-webkit-slider-thumb:active {
    background-color: #F36F27;
}

.form-range::-webkit-slider-runnable-track {
    background-color: #F36F27;
}

.ingridient-list {
    display: grid;
    grid-template-columns: 35% 65%;
}

.tag-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    padding: 20px 25px;
}

.nutri-progressbar .progress {
    --bs-progress-height: 0.3rem;
}

.comment-box-img {
    width: 40px;
    height: 40px;
}

.comment-box-img img {
    border-radius: 14px;
}

.comment-box {
    margin-top: 15px;
    border-radius: 14px;
}

.close-bt {
    background: #FF5050 !important;
    border: 2px solid #fff !important;
    border-radius: 50%;
    width: 43px;
    position: absolute;
    height: 43px;
    top: -10px;
    right: -10px;
}

.edit-cardimg {
    height: 203px;
    width: 100%;
}

.add-plan-form .css-13cymwt-control,
.tag-selected .css-13cymwt-control {
    border: 0px solid #dee2e6 !important;
    padding: 0px !important;
}

.add-plan-form .css-1hb7zxy-IndicatorsContainer,
.tag-selected .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
}

.add-plan-form .css-1fdsijx-ValueContainer,
.tag-selected .css-1fdsijx-ValueContainer {
    border: 2px solid #dee2e6 !important;
    border-radius: 5px;
    padding: 10px;
}

.add-plan-form .css-3w2yfm-ValueContainer,
.tag-selected .css-3w2yfm-ValueContainer {
    border: 2px solid #dee2e6 !important;
    box-shadow: none !important;
    outline: none !important;
    border-radius: 5px;
    padding: 20px;
}

.add-plan-form .css-t3ipsp-control,
.tag-selected .css-t3ipsp-control {
    border: 0px !important;
    box-shadow: none !important;
}

.add-plan-form .css-1p3m7a8-multiValue,
.tag-selected .css-1p3m7a8-multiValue {
    border-radius: 7px !important;
    background: linear-gradient(142deg, #8DC63F 0%, #7FC520 100%) !important;
    box-shadow: 0px 3px 11px 0px rgba(144, 200, 68, 0.30) !important;
    padding: 5px;
}

.add-plan-form .css-3w2yfm-ValueContainer > div,
.tag-selected .css-3w2yfm-ValueContainer > div {
    width: auto !important;
}

.add-plan-form .css-wsp0cs-MultiValueGeneric,
.tag-selected .css-wsp0cs-MultiValueGeneric {
    color: #FFF !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.add-plan-form .css-1p3m7a8-multiValue .css-tj5bde-Svg,
.tag-selected .css-1p3m7a8-multiValue .css-tj5bde-Svg {
    fill: #fff !important;
}

.add-plan-form .css-12a83d4-MultiValueRemove:hover,
.tag-selected .css-12a83d4-MultiValueRemove:hover {
    background-color: #559102 !important;
}

.direction-tabgrid {
    display: grid;
    grid-template-columns: 10% 70% 15%;
    gap: 10px;
}

.direction-tabgrid {
    display: grid;
    grid-template-columns: 3% 71% 23%;
    gap: 10px;
    align-items: center;
}

.custom-modal-header .btn-close {
    filter: invert(1) !important;
    opacity: 1 !important;
    box-shadow: none !important;
}

.exercise-table thead tr th {
    color: #7D7C7C;
    font-weight: 400;
    font-size: 20px;
}

.exercise-table tbody tr {
    /* border-bottom: 1px solid #D6D6D6; */
    /* background: #FBFBFB; */
    font-size: 20px;
    --bs-table-bg: #FBFBFB;

}

.pagination-container .page-link {
    color: #F36F27;
}

.pagination-container .page-item.active {
    --bs-pagination-active-bg: #F36F27;
    --bs-pagination-active-border-color: #F36F27;
}

.pagination-container .page-item.active .page-link {
    color: #fff;
}

.custom-dropdown {
    position: absolute;
    padding: 15px;
    width: 180px;
    top: 45px;
    display: none;
}

.custom-dropdown-2 {
    position: absolute;
    padding: 15px;
    width: 200px;
    top: 45px;
    display: none;
    left: -113px;
}

.custom-dropdown input[type="checkbox"] {
    border-radius: 50%;
}

.custom-dropdown .form-check-input:checked {
    background-color: #F36F27;
    border-color: #F36F27;
}

.custom-dropdown .form-check-input:focus {
    border-color: #F36F2780;
    outline: 0;
    box-shadow: 0 0 0 .25rem #F36F2725;
}

.custom-dropdown label {
    font-weight: 500;
}

.custom-dropdown-2.active,
.custom-dropdown.active {
    display: block;
}

.plan-grid {
    display: grid;
    grid-template-columns: 11% 60% 24%;
    column-gap: 15px;
    align-items: center;
    border-bottom: 1px solid #ECECEC;
}

.plan-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 30px;
}

.square {
    width: 8.485px;
    height: 8.485px;
    transform: rotate(-45deg);
    display: inline-block;
    border-radius: 2px;
}

.meal-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 20px;
    column-gap: 20px;
}

.meal-box {
    width: 100%;
    position: relative;
}

.meal-item {
    display: grid;
    grid-template-columns: 7% 53% 23%;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 10px;
}

.meal-box::before {
    content: '';
    position: absolute;
    width: 1px;
    right: 0px;
    height: 100%;
    background: #F0F0F0;
}

.week-grid {
    display: grid;
    text-align: center;
    grid-template-columns: repeat(7, 1fr);
}

.diet-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    margin-top: 30px;
}

.diet-card h6 {
    font-size: 16px;
    color: #4E4C4C;
}

.diet-card p {
    font-size: 13px;
    color: #505050;
    margin-bottom: 0;
    word-break: break-all;
}

.diet-card-img {
    height: 65px;
    width: 100%;
    border-radius: 6px;
    margin: 10px 0;
}

.diet-card-img img {
    border-radius: 6px;
}

.diet-card span {
    display: block;
    width: 17px;
    height: 2px;
}

.add-plan-form .progress {
    height: 6px;
}

.add-plan-form .progress-bar {
    background: #F36F27;
}

.date-circle {
    border-radius: 50%;
    background: #85C52E;
    width: 30px;
    height: 30px;
    display: inline-flex;
    color: #fff;
    justify-content: center;
    font-size: 17px;
    align-items: center;
    margin: 0px 20px 0px 25px;
}

.add-workout-checkbox input[type="checkbox"] {
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.add-workout-checkbox .form-check-input:checked {
    background-color: #F36F27;
    border-color: #F36F27;
}

.add-workout-checkbox .form-check-input:focus {
    border-color: #F36F2780;
    outline: 0;
    box-shadow: 0 0 0 .25rem #F36F2725;
}

.add-workout-checkbox label {
    display: block;
    padding: 10px;
    width: 100%;
    border-radius: 7px;
    margin-left: 20px;
    border: 1px solid #D6D6D6;
    background: #FBFBFB;
    color: #959595;
    font-size: 18px;
    font-weight: 500;
}

.add-workout-checkbox .form-check-input:checked label {
    border: 1px solid #88C533;
    background: #F8FFEF;
    color: #000;
}

.add-workout-checkbox .form-check {
    display: flex;
    align-items: center;
}

.upload-vdo {
    display: contents;
}

.star-rating > div > div {
    justify-content: center;
}

.workout-video {
    height: 440px;
    position: relative;
}

.recipe-card-img video,
.workout-video video {
    object-fit: cover;
}

.videodetail-video {
    height: 400px;
    width: 660px;
}

.videodetail-video video {
    object-fit: cover;
}

.workout-video button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    width: 65px;
    height: 65px;
}

.bg-PROGRESS_BAR {
    background: #F36F27 !important;
}

.user-detail {
    word-break: break-word;
}

.workout-img {
    height: 520px;
}

.yellow {
    background: #FFC90E;
}

.blue {
    background: #3BCAF7;
}

.lightgreen {
    background: #36FBE4;
}

.pink-2 {
    background: #FC68AE;
}

.breakfast-grid span {
    display: block;
    height: 33px;
    width: 73px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.breakfast-grid {
    display: flex;
    gap: 1.5rem;
    justify-content: end;
    align-items: center;
}

.nutrition-box {
    padding-bottom: 10px;
}

.col-xxl-12 div:last-child button.text-green.text-decoration-underline {
    font-size: 18px;
    margin-top: 1.5rem;
}

.total-data {
    width: 180px;
    /* height: 60px; */
    padding: 12px;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    min-width: 50%;
}

.row.dashboard-container {
    justify-content: space-between;
}

.dashboard-container .col-xxl-3 .p-3.bg-white {
    height: 100%;
}

.daily-nutri-data .data-1 {
    border: 2px solid #FFC90E;
    color: #FFC90E;
}

.daily-nutri-data .data-box h4 {
    min-width: 150px;
    margin-right: 10px;
    margin-bottom: 0;
}

.daily-nutri-data .data-2 {
    border: 2px solid #36FBE4;
    color: #36FBE4;
}

.daily-nutri-data .data-3 {
    border: 2px solid #3BCAF7;
    color: #3BCAF7;
}

.daily-nutri-data .data-4 {
    border: 2px solid #FC68AE;
    color: #FC68AE;
}

.daily-nutri-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin: auto;
    gap: 20px;
    width: 70%;
}

.iwatch-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
}

.exercise-modal-video {
    height: 293px;
    width: 100%;
    position: relative;
}

.video-play-btn {
    background: #C8E89C !important;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    border: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
}

.rbc-toolbar .rbc-btn-group {
    display: none;
}

.rbc-toolbar .rbc-toolbar-label {
    padding: 0 !important;
    text-align: start !important;
    font-weight: 600;
    font-size: 20px;
}

.rbc-button-link {
    background: #F36F27 !important;
    padding: 0px !important;
    border-radius: 50px !important;
    width: 25px !important;
    height: 25px !important;
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    font-family: 'Gilroy' !important;
    margin: 10px !important;
}

.rbc-date-cell {
    text-align: left !important;
}

.workout-status-grid {
    display: grid;
    grid-template-columns: 27% 27% 27% auto;
    column-gap: 10px;
}

.rbc-header {
    font-weight: 600 !important;
}

.meal-plan-header {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 10px;
}

.meal-data-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    margin: 10px;
    /*display: grid;*/
    /*grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;*/
    /*column-gap: 5px;*/
    /*align-items: center;*/
    /*background: #fff;*/
    /*padding: 30px;*/
    /*border-radius: 10px;*/
}

.meal-plan-action button.dropdown-toggle {
    background-color: #FFFFFF;
    border-color: #F36F27;
    padding: 0;
    border-width: 2px;
    width: 80px;
}

.meal-plan-action button.dropdown-toggle:hover,
.meal-plan-action button.dropdown-toggle:active {
    background-color: #F36F27;
}

.meal-plan-action button.dropdown-toggle svg {
    stroke: #F36F27;
    width: 30px;
    height: 30px;
}

.meal-plan-action button.dropdown-toggle:hover svg,
.meal-plan-action button.dropdown-toggle:active svg {
    stroke: #FFFFFF;
}

.meal-plan-action button.dropdown-toggle:after {
    content: none !important;
}

.meal-plan-action .dropdown-menu.show, .meal-plan-item-action .dropdown-menu.show {
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #F6F6F6;
    background: #FFF;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.02);
}

.meal-plan-action .dropdown-menu.show .dropdown-item, .meal-plan-item-action .dropdown-menu.show .dropdown-item {
    color: #737791;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    display: block;
    padding: 10px 20px;
}

.meal-plan-action .dropdown-menu.show .dropdown-item:hover, .meal-plan-item-action .dropdown-menu.show .dropdown-item:hover {
    color: #FFFFFF;
    border-radius: 8px;
    background: #F36F27;
}

.grey-dot {
    display: inline-block;
    width: 10px;
    margin-right: 5px;
    height: 10px;
    border-radius: 20px;
    background: #e3e3e3;
}

.meal-data-list {
    border: 1px solid #dee2e6;
    /*height: 155px;*/
    overflow-y: auto;
    /* border-radius: 0 0 5px 5px; */
}

.meal-data-view-nutrition-btn {
    color: #FFFFFF;
    border-radius: 0;
    background: #F36F27;
    border-color: #F36F27;
    font-size: 14px;
    font-weight: 600;
}

.meal-data-view-nutrition-btn:hover {
    color: #F36F27;
    border-radius: 10px;
    background: #FFFFFF;
    border-color: #F36F27;
}

.text-rotate-90 {
    writing-mode: vertical-rl;
}

.meal-table-grid {
    display: grid;
    grid-template-columns: 3% auto;
    background: #fff;
    padding: 30px;
    border-radius: 10px;
}

.diet-table-text {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.diet-table-text p {
    writing-mode: vertical-rl;
    height: 150px;
    text-align: center;
}

.food-img {
    width: 60px;
    height: 60px;
    margin-right: 15px;
    border-radius: 50%;
}

.food-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.modal-filter-diet {
    height: 420px;
    overflow-y: auto;
    padding: 0;
}

.css-cwhad8-MuiDateCalendar-root {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: 20px !important;
}

.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
    border: 0px solid rgba(0, 0, 0, 0.6) !important;
    background: #77b720;
    color: #fff !important;
}

.grocery-svg-logo svg {
    width: 115px;
}

.grocery-svg-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.perday-meal-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}

.custom-timepicker {
    display: block;
    border: 1px solid #dee2e6 !important;
    padding: 17px;
    outline: none;
    box-shadow: none !important;
}

.custom-timepicker input {
    font-size: 16px !important;
    color: #000 !important;
}

.custom-timepicker1 {
    display: block;
    border: 1px solid #dee2e6 !important;
    padding: 7px 5px;
    outline: none;
    box-shadow: none !important;
}

.ant-picker-dropdown {
    z-index: 9999;
}

.category {
    color: #fff !important;
    background: #F36F27 !important;
}

.cursor {
    cursor: pointer !important;
}

.noDisplay {
    display: none
}

.direction_side {
    width: 90%;
}

.exercise-modal-video .video-back {
    border: none;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 9;
    background: #fff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
}

.exercise-modal-video iframe,
.exercise-modal-video img,
.exercise-modal-video video {
    object-fit: cover;
    border-radius: 10px;
}

.recipe-box video {
    border-radius: 10px 10px 0 0;
}

.videodetail-video iframe, .videodetail-video video {
    border-radius: 13px;
}

.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected):hover {
    color: #000 !important;
}

.img-product img {
    object-fit: cover;
    height: 100%;
    min-height: 150px;
    max-height: 150px;
}

.meal-planner-item {
    border: 0 solid grey;
    background: #fff;
    /*padding: 10px;*/
    min-height: 150px;
    width: 100%;
}

/* .meal-planner-item:nth-child(1) {
    border: 0px solid grey;
    text-align: center;
    height: 150px;
} */

.meal-planner-image {
    width: 100%;
    height: 215px;
    position: relative;
}

.meal-planner-image img {
    border-radius: 1rem 1rem 0 0;
}

.meal-planner-image .meal-name{position: absolute;bottom: 10px;left: 10px;border-radius: 30px;background: rgba(0, 0, 0, 0.42);backdrop-filter: blur(3px);padding: 6px 24px;width: fit-content;color: #fff;margin-bottom: 0;right: 10px;}
.meal-planner-item .servings-card{display: flex;align-items: center;justify-content: space-between;padding: 12px;}
.meal-planner-item .servings-card .counter-div{display: flex;align-items: center;gap: 0 14px;}
.meal-planner-item .servings-card .counter-btn{border: 0;border-radius: 50%;width: 35px;height: 35px;display: flex;align-items: center;justify-content: center;transition: all 0.2s ease-in-out;}
.meal-planner-item .servings-card .counter-btn:hover{background: #F36F27;}
.meal-planner-item .servings-card .counter-btn:hover svg{fill: #fff;}
.meal-planner-item .servings-card .counter-btn.plus-btn svg{width: 28px !important;height: 28px !important;}
.meal-planner-item .servings-card .number{font-size: 16px;font-weight: 600;color: #000;}
.meal-planner-item .servings-card .servings-text{margin-bottom: 0;color: #F36F27;font-size: 16px;font-weight: 500;line-height: normal;}

.meal-planner-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    margin: 10px;
}

.text-align-left {
    text-align: left !important;
}

.diamond-shape {
    display: inline-block;
    width: 9px;
    margin-right: 5px;
    height: 9px;
    transform: rotate(45deg);
    border-radius: 0;
    background: #ffc107;
}

.green-line-2 {
    display: inline-block;
    width: 20px;
    margin: 10px auto;
    height: 3px;
    border-radius: 50px;
    text-align: center;
    background: #8dc63f;
}

.cs_width .col-sm-4 {
    width: 22.333% !important;
}

button.workout-btn {
    width: 470px !important;
}

.workout-equipment.col-sm-4 {
    width: 33% !important;
}

.bg-COLOR_CALORIES {
    background: #FF964A !important;
}

.bg-COLOR_FATS {
    background: #FB68AF !important;
}

.bg-COLOR_CARBS {
    background: #4EC5E8 !important;
}

.bg-COLOR_SUGAR {
    background: #AA8EFD !important;
}

.bg-COLOR_PROTEIN {
    background: #36FBE0 !important;
}

/* .bg-COLOR_CHOLESTEROL {
    background: #F089D1 !important;
} */
.bg-COLOR_VITAMINA {
    background: #F089D1 !important;
}

.bg-COLOR_VITAMINC {
    background: #ADD0D5 !important;
}

.bg-COLOR_CALCIUM {
    background: #F8C91B !important;
}

.bg-COLOR_IRON {
    background: #FFF400 !important;
}

.display-none {
    display: none;
}


.display-none.show {
    display: block;
    position: absolute;
    width: 750px;
    border: 2px solid #d9d9d9;
    right: 10px;
    padding: 15px;
    max-height: 35em;
    height: 750px;
    overflow-y: auto;
    background: #fff;
    top: 57px;
}

.notification-alert-content {
    display: flex;
    width: 100%;
    height: 60px;
    border: 1px solid rgb(128, 128, 128);
    border-radius: 5px;
}

.notification-alert-content img {
    width: 25%;
    height: 100%;
    border-radius: 3px;
}

.notification-alert-content div {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    width: 100%;
    height: 100%;
    background: #3B82F6;
    color: white;
}

.notification-alert-content div h4 {
    font-size: 15px;
    padding: 2px
}

.notification-alert-content div p {
    font-size: 14px;
}


.notification-ui a:after {
    display: none;
}

.notification-ui_icon {
    position: relative;
}

.notification-ui_icon .unread-notification {
    display: inline-block;
    height: 7px;
    width: 7px;
    border-radius: 7px;
    background-color: #66BB6A;
    position: absolute;
    top: 7px;
    left: 12px;
}

/* 23-11-2023 */
.product_list_table {
    overflow-x: scroll !important;
}

.table-responsive {
    overflow-x: scroll;
}

.product_list_table::-webkit-scrollbar, .table-responsive::-webkit-scrollbar {
    width: 10px;
}

.product_list_table::-webkit-scrollbar-track, .table-responsive::-webkit-scrollbar-track {
    background: none;
}

.product_list_table::-webkit-scrollbar-thumb, .table-responsive::-webkit-scrollbar-thumb {
    background: #888;
}

.product_list_table::-webkit-scrollbar-thumb:hover, .table-responsive::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* 23-11-2023     */
@media (min-width: 900px) {
    .notification-ui_icon .unread-notification {
        left: 20px;
    }
}

.notification-ui_dd {
    padding: 0;
    border-radius: 10px;
    -webkit-box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.16);
    box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.16);
    border: 0;
    max-width: 400px;
}

@media (min-width: 900px) {
    .notification-ui_dd {
        min-width: 400px;
        position: absolute;
        right: 20px;
        top: 70px;
    }
}

.notification-ui_dd:after {
    content: "";
    position: absolute;
    top: -30px;
    left: calc(76% - 7px);
    border-top: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
    border-left: 15px solid transparent;
}

.notification-ui_dd .notification-ui_dd-header {
    border-bottom: 1px solid #ddd;
    padding: 15px;
}

.notification-ui_dd .notification-ui_dd-header h3 {
    margin-bottom: 0;
}

.notification-ui_dd .notification-ui_dd-content {
    max-height: 500px;
    overflow: auto;
}

.notification-ui_dd-footer {
    text-align: center;
    padding: 20px;
}

.notification-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    /* justify-content: space-between; */
    padding: 20px 0;
    margin: 0 25px;
    border-bottom: 1px solid #ddd;
}

.notification-list--unread {
    position: relative;
}

.notification-list--unread:before {
    content: "";
    position: absolute;
    top: 0;
    left: -25px;
    height: calc(100% + 1px);
    border-left: 2px solid #29B6F6;
}

.notification-list .notification-list_img img {
    height: 48px;
    width: 48px;
    border-radius: 50px;
    margin-right: 20px;
}

.notification-list .notification-list_detail p {
    margin-bottom: 5px;
    line-height: 1.2;
}

.notification-list .notification-list_feature-img img {
    height: 48px;
    width: 48px;
    border-radius: 5px;
    margin-left: 20px;
}


.rbc-toolbar-label svg {
    color: #000;
    width: 20px;
    height: 20px;
}

.filter-days button {
    background: #F36F27;
    color: #fff;
    outline: none;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    padding: 8px 20px;
}

.filter-days button:nth-child(odd) {
    margin-right: .8rem;
}

@media screen and (min-width: 1499px) {
    .login-logo {
        background-size: 103%;
    }
}

@media screen and (max-width: 1800px) and (min-width: 1600px) {
    .tag-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 1600px) and (min-width: 1400px) {
    .tag-list {
        grid-template-columns: repeat(2, 1fr);
    }

    .direction-tabgrid {
        grid-template-columns: 3% 50% 42%;
    }
}

@media screen and (max-width: 1440px) {
    .recipe-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .product-grid {
        grid-template-columns: repeat(4, 1fr);
    }

    .plan-grid {
        grid-template-columns: 14% 48% 31%;
    }

    .position-xl-static {
        position: unset !important;
    }

    .h-xl-auto {
        height: auto !important;
    }

    .diet-grid {
        grid-template-columns: repeat(5, 1fr);
    }

    .meal-grid {
        grid-template-columns: repeat(4, 1fr);
        gap: 25px;
    }

    .sidebar-area {
        height: 64vh;
    }

    .sidebar-link {
        height: 63vh;
    }

    .sidebar-link a {
        padding: 10px 10px;
        font-size: 16px;
    }

}

@media screen and (max-width: 1024px) {
    .recipe-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .login-logo img {
        width: 60%;
        margin: auto;
    }

    .diet-grid {
        grid-template-columns: repeat(4, 1fr);
    }

    .dashboard-layout {
        grid-template-columns: 25% 75%;
    }

    .user-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .product-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .plan-list {
        grid-template-columns: repeat(1, 1fr);
    }

    .exercise-table tbody tr {
        font-size: 15px;
    }

    .exercise-table thead tr th {
        font-size: 18px;
    }

    .msg-type {
        grid-template-columns: 5% 79% 16%;
    }
}

@media screen and (max-width: 767px) {
    .product-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 540px) {
    .product-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}

.bg-light-salmon {
    background-color: #FFA07A !important;
}

.bg-lavender {
    background-color: #E6E6FA !important;
}

.bg-gold-1 {
    background-color: #FFD700 !important;
}

.bg-yellow-1 {
    background-color: #FFFF00 !important;
}

.bg-dark-pink {
    background-color: #CC66FF !important;
}

.bg-blue-green {
    background-color: #80FFDF !important;
}

.bg-powder-blue {
    background-color: #B0E0E6 !important;
}

.bg-orange-red-1 {
    background-color: #FF4500 !important;
}

.bg-aqua {
    background-color: #00AAFF !important;
}

.bg-moderate-cyan {
    background-color: #4ECDC4 !important;
}

.bg-light-blue {
    background-color: #ADD8E6 !important;
}

.bg-light-yellow {
    background-color: #F7EF81 !important;
}

.bg-brown {
    background-color: #D2691E !important;
}

.bg-navy-blue {
    background-color: #000080 !important;
}

.bg-sky-blue {
    background-color: #87CEEB !important;
}

.bg-sliver {
    background-color: #C0C0C0 !important;
}

.bg-neon-light {
    background-color: #A6FD29 !important;
}

.bg-nasturtium {
    background-color: #FE5E41 !important;
}

.bg-cadet-blue {
    background-color: #5F9EA0 !important;
}

.bg-yellow-green {
    background-color: #9ACD32 !important;
}

.bg-hot-pink {
    background-color: #FF69B4 !important;
}

.bg-steel-blue {
    background-color: #4682B4 !important;
}

.bg-rosy-brown {
    background-color: #BC8F8F !important;
}

.bg-lime-blue {
    background-color: #55FFE1 !important;
}

.bg-dark-orange {
    background-color: #FF8C00 !important;
}

.bg-firebrick {
    background-color: #B22222 !important;
}

.bg-teal {
    background-color: #008080 !important;
}

.bg-light-khaki {
    background-color: #F0E68C !important;
}

.bg-magenta {
    background-color: #FF00FF !important;
}

@media (min-width: 992px) {
    .modal-xll {
        --bs-modal-width: 1000px;
    }
}

.profile-tab{display: flex;flex-direction: column;justify-content: flex-start;height: 100%;gap: 10px 0;}
.custom-filter-card{padding: 18px 26px;background: #fff;border-radius: 16px;height:100%;border:1px solid #f2f3f5}
.profile-tab .nav-tabs .nav-link.active{background-color:transparent}
.profile-tab .btns{margin-top:auto}
.modal-filter-diet{position: relative;display: flex;flex-direction: column;}
.modal-filter-diet .loader-overlay{position: absolute}
.plus-btns{display: flex;align-items: center;gap: 0 9px;width:100%;justify-content:flex-end;padding:0 0 5px 0}
.plus-btns .rounded-btn{width: 35px;height: 35px;border-radius: 50%;display:flex;align-items:center;justify-content:center;background-color:#e4e4e4;border-color:#e4e4e4;transition:all 0.3s ease-in-out}
.plus-btns .rounded-btn .icons{font-size:12px;color:#9c9c9c;transition:all 0.3s ease-in-out}
.plus-btns .rounded-btn:hover{background-color:#F36F27}
.plus-btns .rounded-btn:hover .icons{color:#fff}
.plus-btns .counter{font-size:16px;font-weight:bold;line-height:26px}

.plus-btns .rounded-btn:hover {
    background-color: #F36F27
}

.meal-type-tag{display: flex;flex-wrap: wrap;gap:6px;}
.meal-type-tag .meal-single-tag{background: #f8f8f8;border-radius:14px;padding:8px 20px;cursor: pointer;}
.meal-type-tag.add-recipe-tag .meal-single-tag{background: rgba(239, 239, 239, 0.59);}

.custom-tabs{gap: 0 40px;padding:0 !important;border-bottom:0;margin-bottom:15px}
.custom-tabs .nav-item .nav-link{background: transparent;border: 0;color: #000;font-size: 16px;font-weight: 600;padding: 0;position:relative}
.custom-tabs .nav-item .nav-link::before{content: "";right: -20px;top: 50%;transform: translate(-50%, -50%);width: 1px;height: 13px;position: absolute;background: #000;}
.custom-tabs .nav-item:nth-last-child(1) .nav-link::before{display:none}


.filter-modal .modal-content{background-color:#fafafa}
.filter-for-text{font-size: 20px;font-weight: 600;line-height: normal;margin-bottom:0}

.custom-searchbar{background:#f8f8f8;padding:8px 12px;border-radius: 12px;}
.custom-searchbar input{color:#000;background-color:#f8f8f8 !important}

.custom-pagination{list-style: none;margin: 0;padding: 0;display: flex;align-items: center;justify-content: center;background: #fff;margin-top: auto;position: sticky;bottom: 0;padding-top:1px}
.custom-pagination li{width: 35px;height: 35px;display: flex;align-items: center;justify-content: center;border-radius: 4px;color:#000}
.custom-pagination li.selected{background:#F36F27;color:#fff;}
.custom-pagination .disabled a{color:rgba(33, 37, 41, 0.75)}
.custom-pagination li a{font-size: 16px;font-weight: 500;text-decoration: none;}


.nutrition-data-modal .nutrition-tabs-sec{margin-bottom: 24px;border: 0;justify-content: center;gap: 0 16px;}
.nutrition-data-modal .modal-header{padding: 0;border: 0;}
.nutrition-data-modal .btn-close{position: absolute;right: 40px;top: 40px;z-index: 1;}
.nutrition-data-modal .modal-content{border-radius: 8px;background: #FFF;}
.nutrition-data-modal .modal-body{padding: 24px;}
.nutrition-data-modal .nutrition-tabs-sec .nav-link{padding: 16px 20px;display: flex;justify-content: center;align-items: center;border-radius: 8px;background: #F6F6F6;color: #737791;font-size: 16px;font-weight: 600;line-height: 22px;width: 217px;height: 44px;margin: 0;border: 1px solid #EBF0F4;}
.nutrition-data-modal .nutrition-tabs-sec .nav-link.active{border-radius: 8px;background: #F36F27;color: #fff;border-color: #F36F27;}
.nutrition-data-modal .card-row{display: flex;gap: 16px;width: 100%;flex-wrap: wrap;justify-content: center;}
.nutrition-data-modal .main-card{display: flex;border-radius: 8px;border: 1px solid #E9EEF2;box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);flex-direction: column;width: 204px;}
.nutrition-data-modal .main-card .main-header{display: flex;height: 41px;padding: 0px 10px;justify-content: center;align-items: center;border-bottom: 1px solid #E9EEF2;}
.nutrition-data-modal .main-card .main-header .date{color: #32395F;font-size: 16px;font-weight: 500;line-height: normal;margin-bottom: 0;}
.nutrition-data-modal .main-card .card-content{display: flex;padding: 12px;flex-direction: column;align-items: center;gap: 16px 0;}
.nutrition-data-modal .main-card .card-content .nutrition-info{width: 100%;display: flex;flex-direction: column;gap: 8px 0;}
.nutrition-data-modal .main-card .card-content .nutrition-info .name{font-size: 14px;font-weight: 500;line-height: normal;margin-bottom: 0;word-break: break-word;}
.nutrition-data-modal .main-card .card-content .nutrition-info .tags-div{display: flex;align-items: center;justify-content: space-between;gap: 0 8px;}
.nutrition-data-modal .main-card .card-content .nutrition-info .tags-div .tag{display: flex;padding: 5px 6px;justify-content: center;align-items: center;border-radius: 1000px;color: #32395F;font-size: 14px;font-weight: 500;line-height: normal;width: 85px;word-break: break-word;}

/* Color CSS  */

/* Red Color  */
.nutrition-data-modal .nutrition-info.red-color .name{color: #F36F27;}
.nutrition-data-modal .nutrition-info.red-color .tags-div .tag{background: rgba(243, 111, 39, 0.18);}
/* Blue Color  */
.nutrition-data-modal .nutrition-info.blue-color .name{color: #9992DF;}
.nutrition-data-modal .nutrition-info.blue-color .tags-div .tag{background: rgba(153, 146, 223, 0.11);}
/* Dark Blue Color  */
.nutrition-data-modal .nutrition-info.darkblue-color .name{color: #A271B1;}
.nutrition-data-modal .nutrition-info.darkblue-color .tags-div .tag{background: rgba(162, 113, 177, 0.11);}
/* Light Green Color  */
.nutrition-data-modal .nutrition-info.lightgreen-color .name{color: #7ED4C4;}
.nutrition-data-modal .nutrition-info.lightgreen-color .tags-div .tag{background: rgba(126, 212, 196, 0.11);}
/* Light Blue Color  */
.nutrition-data-modal .nutrition-info.lightblue-color .name{color: #A5DCE5;}
.nutrition-data-modal .nutrition-info.lightblue-color .tags-div .tag{background: rgba(165, 220, 229, 0.11);}
/* Dark Red Color  */
.nutrition-data-modal .nutrition-info.darkred-color .name{color: #EF4A25;}
.nutrition-data-modal .nutrition-info.darkred-color .tags-div .tag{background: rgba(239, 74, 37, 0.11);}
/* Sky Blue Color  */
.nutrition-data-modal .nutrition-info.skyblue-color .name{color: #42A2DA;}
.nutrition-data-modal .nutrition-info.skyblue-color .tags-div .tag{background: rgba(66, 162, 218, 0.11);}
/* Extra Light Green Color  */
.nutrition-data-modal .nutrition-info.extralightgreen-color .name{color: #5AC4BE;}
.nutrition-data-modal .nutrition-info.extralightgreen-color .tags-div .tag{background: rgba(90, 196, 190, 0.11);}
/* Extra Light Blue Color  */
.nutrition-data-modal .nutrition-info.extralightblue-color .name{color: #80B2C2;}
.nutrition-data-modal .nutrition-info.extralightblue-color .tags-div .tag{background: rgba(128, 178, 194, 0.11);}
/* Yellow Color  */
.nutrition-data-modal .nutrition-info.yellow-color .name{color: #FFF800;}
.nutrition-data-modal .nutrition-info.yellow-color .tags-div .tag{background: rgba(218, 255, 68, 0.11);}
/* Extra Dark Red Color  */
.nutrition-data-modal .nutrition-info.extradarkred-color .name{color: #D46B28;}
.nutrition-data-modal .nutrition-info.extradarkred-color .tags-div .tag{background: rgba(212, 107, 40, 0.11);}


/* Favourite Recipe Card  */
.fav-recipe-box{position: relative;}
.fav-recipe-box .icon-div{width: 45px;height: 45px;background: #fff;border-radius: 50%;position: absolute;right: 8px;top: 13px;border: 0;display: flex;align-items: center;justify-content: center;}
.icon-div-share{right: 65px !important;}
.fav-recipe-box .icon-div .heart-icon-svg path{fill: transparent;stroke: #FF1919;transition: all 0.2s ease-in-out;}
.fav-recipe-box .icon-div.active .heart-icon-svg path{fill: #FF1919;}


.nutrition-data-modal .nutrition-info .name.ntext-light-salmon{color: #FFA07A !important;}
.nutrition-data-modal .nutrition-info .name.ntext-lavender{color: #E6E6FA !important;}
.nutrition-data-modal .nutrition-info .name.ntext-gold-1{color: #FFD700 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-yellow-1{color: #FFFF00 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-dark-pink{color: #CC66FF !important;}
.nutrition-data-modal .nutrition-info .name.ntext-blue-green{color: #80FFDF !important;}
.nutrition-data-modal .nutrition-info .name.ntext-powder-blue{color: #B0E0E6 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-orange-red-1{color: #FF4500 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-aqua{color: #00AAFF !important;}
.nutrition-data-modal .nutrition-info .name.ntext-moderate-cyan{color: #4ECDC4 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-light-blue{color: #ADD8E6 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-light-yellow{color: #F7EF81 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-brown{color: #D2691E !important;}
.nutrition-data-modal .nutrition-info .name.ntext-navy-blue{color: #000080 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-sky-blue{color: #87CEEB !important;}
.nutrition-data-modal .nutrition-info .name.ntext-sliver{color: #C0C0C0 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-neon-light{color: #A6FD29 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-nasturtium{color: #FE5E41 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-cadet-blue{color: #5F9EA0 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-yellow-green{color: #9ACD32 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-hot-pink{color: #FF69B4 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-steel-blue{color: #4682B4 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-rosy-brown{color: #BC8F8F !important;}
.nutrition-data-modal .nutrition-info .name.ntext-lime-blue{color: #55FFE1 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-dark-orange{color: #FF8C00 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-firebrick{color: #B22222 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-teal{color: #008080 !important;}
.nutrition-data-modal .nutrition-info .name.ntext-light-khaki{color: #F0E68C !important;}
.nutrition-data-modal .nutrition-info .name.ntext-magenta{color: #FF00FF !important;}

.nutrition-data-modal .nutrition-info .tag.nbg-light-salmon{background-color: rgba(255, 160, 122, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-lavender{background-color: rgba(230, 230, 250, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-gold-1{background-color: rgba(255, 215, 0, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-yellow-1{background-color: rgba(255, 255, 0, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-dark-pink{background-color: rgba(204, 102, 255, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-blue-green{background-color: rgba(128, 255, 223, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-powder-blue{background-color: rgba(176, 224, 230, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-orange-red-1{background-color: rgba(255, 69, 0, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-aqua{background-color: rgba(0, 170, 255, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-moderate-cyan{background-color: rgba(78, 205, 196, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-light-blue{background-color: rgba(173, 216, 230, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-light-yellow{background-color: rgba(247, 239, 129, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-brown{background-color: rgba(210, 105, 30, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-navy-blue{background-color: rgba(0, 0, 128, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-sky-blue{background-color: rgba(135, 206, 235, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-sliver{background-color: rgba(192, 192, 192, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-neon-light{background-color: rgba(166, 253, 41, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-nasturtium{background-color: rgba(254, 94, 65, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-cadet-blue{background-color: rgba(95, 158, 160, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-yellow-green{background-color: rgba(154, 205, 50, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-hot-pink{background-color: rgba(255, 105, 180, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-steel-blue{background-color: rgba(70, 130, 180, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-rosy-brown{background-color: rgba(188, 143, 143, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-lime-blue{background-color: rgba(85, 255, 225, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-dark-orange{background-color: rgba(255, 140, 0, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-firebrick{background-color: rgba(178, 34, 34, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-teal{background-color: rgba(0, 128, 128, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-light-khaki{background-color: rgba(240, 230, 140, 0.11) !important;}
.nutrition-data-modal .nutrition-info .tag.nbg-magenta{background-color: rgba(255, 0, 255, 0.11) !important;}


/* Add Recipe Design  */
.add-recipe-page .heading-div{width: 100%;border-bottom: 1px solid #E6E6E6;}
.add-recipe-page .heading-div .heading-title{margin-bottom: 0;font-size: 16px;line-height: 24px;font-weight: 500;color: #74D473;padding-bottom: 9px;}
.add-recipe-page .import-from-web{padding: 42px 0;}
.add-recipe-page .import-from-web .inner-content{max-width: 100%;height: 200px;border: 1px dashed #74D473;border-radius: 5px;background: rgba(39, 95, 145, 0.02);margin: auto;position: relative;}
.add-recipe-page .import-from-web .inner-content .img-div{display: flex;justify-content: center;align-items: center;flex-direction: column;height: 100%;gap: 13px 0;cursor: pointer;}
.add-recipe-page .import-from-web .inner-content .img-div .import-text{margin-bottom: 0;font-size: 16px;line-height: 24px;color: rgba(149, 149, 149, 1);font-weight: 500;}
.add-recipe-page .inner-file{height: 0;overflow: hidden;width: 0;opacity: 0;position: absolute;left: 0;top: 0;}

.add-recipe-inner{padding: 28px 0 32px 0;}
.add-recipe-inner .add-img-div{width: 100%;height: 100%;border: 1px dashed rgba(60, 164, 198, 1);background: rgba(116, 212, 115, 0.11);border-radius: 5px;display: flex;align-items: center;justify-content: center;min-height: 200px;}
.add-recipe-inner .add-img-div .img-div{display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;height: 100%;width: 100%;gap: 22px 0;padding: 34px 0;}
.add-recipe-inner .add-img-div .img-div .add-text{font-size: 16px;line-height: 24px;font-weight: 600;color: rgba(195, 195, 195, 1);margin-bottom: 0;}
.add-recipe-inner .img-row{display: grid;grid-template-columns: repeat(3, 1fr);gap: 16px 21px;}
.add-recipe-inner .img-row .inner-img{position: relative;}
.add-recipe-inner .img-row .inner-img .add-recipe-img{width: 100%;height: 200px;border-radius: 12px;object-fit: cover;}
.add-recipe-inner .btns{display: flex;gap: 0 7px;align-items: center;padding: 0 20px;justify-content: end;margin-top: 23px;}
.add-recipe-inner .btns  .green-outline-btn{display: flex;align-items: center;justify-content: center;width: fit-content;border: 1px solid rgba(116, 212, 115, 1);font-size: 14px;line-height: 16px;color: rgba(116, 212, 115, 1);border-radius: 8px;padding: 12px 32px;text-decoration: none;font-weight: 500;}
.add-recipe-inner .btns  .green-btn{display: flex;align-items: center;justify-content: center;width: fit-content;border: 1px solid rgba(116, 212, 115, 1);font-size: 14px;line-height: 16px;color: #fff;border-radius: 8px;padding: 12px 32px;text-decoration: none;background: rgba(116, 212, 115, 1);font-weight: 500;}

.form-tag-div{padding: 14px 21px;border: 1px solid rgba(116, 212, 115, 0.41);border-radius: 6px;height: 100%;}
.form-tag-div .form-group{display: flex;flex-direction: column;}
.form-tag-div .form-row{gap: 15px 0;}
.label-tag{font-size: 14px;line-height: 16px;font-weight: 600;color: rgba(47, 46, 65, 1);margin-bottom: 8px;}
.form-tag-div .form-group  .input-tag{border: 1px solid rgba(230, 230, 230, 1);border-radius: 8px;height: 40px;padding: 8px 16px;background: transparent;}
.form-tag-div .form-group  .input-tag:focus{border: 1px solid rgba(230, 230, 230, 1);}
.form-tag-div .form-group  .input-tag:focus-visible{outline: 0;}

.form-tag-div .tag-sec{height: 100%;overflow: hidden;display: flex;flex-direction: column;}
.form-tag-div .tag-sec .tag-bg{background: rgba(239, 239, 239, 0.59);width: 100%;height: 100%;border-radius: 10px;}
.form-tag-div .tag-sec .tag-div{border-radius: 10px;padding: 17px 10px 24px 10px;}
.form-tag-div .tag-sec .tag-div{display: flex;gap: 14px;flex-wrap: wrap;}
.form-tag-div .tag-sec .tag-div .tag{padding: 5px 10px 5px 10px;border-radius: 10px;background: rgba(116, 212, 115, 1);font-size: 12px;line-height: 14px;color: #fff;height: fit-content;position: relative;}
.form-tag-div .tag-sec .tag-div .tag .close{position: absolute;right: -5px;top: -5px;border-radius: 50%;border: 0;background: red;color: #fff;
font-size: 16px;width: 15px;height: 15px;display: flex;align-items: center;justify-content: center;}
.form-tag-div .tag-sec .tag-input-div{display: flex;flex-direction: row;gap: 0 15px;padding: 0 10px;padding-bottom: 15px;}
.form-tag-div .tag-sec .tag-input-div .save-btn{padding: 8px 12px;}
.form-tag-div .form-group.tag-input-div .input-tag{flex: 1;}


.bottom-sec{padding: 16px 0 37px 0;}
.bottom-sec .ingredients-div{position: relative;}
.bottom-sec .bottom-tab-row{gap: 25px 0;}
.bottom-sec .add-div{width: 100%;height: 100%;border-radius: 6px;border: 1px solid rgba(116, 212, 115, 0.41);background: transparent;display: flex;align-items: center;justify-content: center;padding: 30px 0 23px 0;position: relative;cursor: pointer;}
.bottom-sec .add-div .inner-content{display: flex;flex-direction: column;gap: 10px 0;width: 100%;padding: 0 25px;}
.bottom-sec .add-div .inner-content .sub-text{font-size: 14px;line-height: 16px;color: rgba(134, 134, 134, 1);font-weight: 500;text-align: center;margin-bottom: 0;}
.bottom-sec .add-div .inner-content .add-icon-div{display: flex;flex-direction: column;align-items: center;justify-content: center;border: 1px dashed rgba(116, 212, 115, 1);background: rgba(116, 212, 115, 0.11);padding: 11px;border-radius: 5px;gap: 10px 0;margin: auto;width: 280px;}
.bottom-sec .add-div .inner-content .add-icon-div .plus-icon{height: 40px;}
.bottom-sec .add-div .inner-content .add-icon-div .add-text{font-size: 14px;line-height: 24px;font-weight: 400;color: rgba(195, 195, 195, 1);margin-bottom: 0;}
.bottom-sec .nutrition-div .inner-card{border: 1px solid rgba(116, 212, 115, 0.41);border-radius: 6px;padding: 16px;height: 100%;display: flex;flex-direction: column;}
.bottom-sec .nutrition-div .inner-card .list-div{display: flex;flex-direction: column;gap: 12px 0;width: 100%;padding-bottom: 15px;}
.bottom-sec .nutrition-div .inner-card .amount-tag{margin-bottom: 15px;}
.bottom-sec .nutrition-div .inner-card .list-div .list{display: grid;grid-template-columns: repeat(3,1fr);align-items: center;}
.bottom-sec .nutrition-div .inner-card .list-div .list .name, .bottom-sec .nutrition-div .inner-card .list-div .list .gm,.bottom-sec .nutrition-div .inner-card .list-div .list .perc{font-size: 14px;line-height: 16px;font-weight: 400;color: rgba(145, 145, 145, 1);word-wrap: break-word;word-break: break-all;}
.bottom-sec .nutrition-div .inner-card .list-div .list .gm{margin: auto;text-align: right;width: 100%;}
.bottom-sec .nutrition-div .inner-card .list-div .list .perc{position: relative;}
.bottom-sec .nutrition-div .inner-card .list-div .list .perc::before{width: 10px;height: 10px;border-radius: 50%;content: "";position: absolute;right: 60px;top: 50%;transform: translate(0, -50%);}
.bottom-sec .nutrition-div .inner-card .list-div .list.yellow-circle .perc::before{background: rgba(255, 199, 39, 1);}
.bottom-sec .nutrition-div .inner-card .list-div .list.red-circle .perc::before{background: rgba(190, 30, 45, 1);}
.bottom-sec .nutrition-div .inner-card .list-div .list.blue-circle .perc::before{background: rgba(0, 121, 172, 1);}
.bottom-sec .nutrition-div .inner-card .list-div .list.skyblue-circle .perc::before{background:rgba(39, 242, 255, 1);}
.bottom-sec .nutrition-div .inner-card .list-div .list.light-circle .perc::before{background:rgba(230, 230, 230, 1);}

.bottom-sec .nutrition-div .inner-card .total-div{background: rgba(192, 251, 191, 0.58);border-radius: 8px;padding: 10px;margin-top: auto;display: flex;gap: 0 10px;}
.bottom-sec .nutrition-div .inner-card .total-div .daily-value{font-size: 16px;line-height: 24px;color: rgba(13, 13, 13, 1);font-weight: 600;}
.bottom-sec .nutrition-div .inner-card .total-div .output-value{font-size: 16px;line-height: 24px;color: rgba(116, 212, 115, 1);font-weight: 600;}

.bottom-sec .tabs-div .nav-link{background-color: transparent;border: 0;font-size: 16px;line-height: 24px;font-weight: 600;color: rgba(177, 177, 177, 1);border-bottom: 2px solid transparent;padding-left: 0;padding-right: 0;}
.bottom-sec .tabs-div .nav-tabs{gap: 0 20px;}
.bottom-sec .nutrition-div{height: 100%;display: flex;flex-direction: column;}
.bottom-sec .tabs-div .nav-link.active{color: #000;border-bottom: 2px solid rgba(116, 212, 115, 1);}
.bottom-sec .tabs-div .tab-content{margin-top: 17px;}
.bottom-sec .tabs-div .tab-content .add-div{border: 1px dashed rgba(116, 212, 115, 1);}
.bottom-sec .tabs-div .tab-content .add-div .inner-content .add-icon-div{background: transparent;border: 0;}

.add-recipe-page .btn-div{display: flex;align-items: center;justify-content: center;}
.add-recipe-page .save-btn{background: rgba(116, 212, 115, 1);border-radius: 8px;font-size: 14px;line-height: 24px;color: #fff;text-decoration: none;padding: 12px 170px;}

.addingredients-modal .nav-link{border: 0;background: transparent;padding-left: 0;padding-right: 0;font-size: 16px;line-height: 24px;color: rgba(177, 177, 177, 1);font-weight: 600;}
.addingredients-modal .nav-link.active{color: #000;border-bottom: 2px solid rgba(116, 212, 115, 1);}
.addingredients-modal .nav-tabs{gap: 0 25px;}
.addingredients-modal .tab-content .details-tab{padding: 40px 0 20px 0;}
.addingredients-modal .tab-content .nutrients-tab{padding: 20px 0;}
.addingredients-modal .form-group{display: flex;flex-direction: column;}
.addingredients-modal .form-row{gap: 15px 0;}
.addingredients-modal .form-group  .input-tag{border: 1px solid rgba(230, 230, 230, 1);border-radius: 8px;height: 40px;padding: 8px 16px;background: transparent;}
.addingredients-modal .form-group  .input-tag:focus{border: 1px solid rgba(230, 230, 230, 1);}
.addingredients-modal .form-group  .input-tag:focus-visible{outline: 0;}
.addingredients-modal .btn-div{display: flex;align-items: center;justify-content: center;padding-top: 44px;}
.addingredients-modal .save-btn{background: rgba(116, 212, 115, 1);border-radius: 8px;font-size: 14px;line-height: 24px;color: #fff;text-decoration: none;padding: 12px 170px;}

.addingredients-modal .inner-card{height: 100%;display: flex;flex-direction: column;max-width: 100%;}
.addingredients-modal .inner-card .list-div{display: flex;flex-direction: column;gap: 12px 0;max-width: 50%;}
.addingredients-modal .inner-card .amount-tag{margin-bottom: 15px;}
.addingredients-modal .inner-card .list-div .list{display: grid;grid-template-columns: repeat(3,1fr);align-items: center;}
.addingredients-modal .inner-card .list-div .list .name, .addingredients-modal .inner-card .list-div .list .gm, .addingredients-modal .inner-card .list-div .list .perc{font-size: 14px;line-height: 16px;font-weight: 400;color: rgba(145, 145, 145, 1);word-wrap: break-word;word-break: break-all;}
.addingredients-modal .inner-card .list-div .list .gm{margin: auto;text-align: right;width: 100%;}
.addingredients-modal .inner-card .list-div .list .perc{position: relative;}
.addingredients-modal .inner-card .list-div .list .perc::before{width: 10px;height: 10px;border-radius: 50%;content: "";position: absolute;right: 60px;top: 50%;transform: translate(0, -50%);}
.appended-input .info-btn{position: absolute;position: absolute;left: 18px;top: 50%;transform: translate(0, -50%);width: 18px;height: 18px;border-radius: 50%;background: rgba(195, 195, 195, 1);border: 0;min-height: 18px;max-height: 18px;display: flex;align-items: center;justify-content: center;padding: 0;}
.appended-input .info-btn:hover{background: rgba(195, 195, 195, 1);}
.appended-input .input-tag{padding-left: 48px !important;}
.addingredients-modal .inner-card .list-div .list.yellow-circle .perc::before{background: rgba(255, 199, 39, 1);}
.addingredients-modal .inner-card .list-div .list.red-circle .perc::before{background: rgba(190, 30, 45, 1);}
.addingredients-modal .inner-card .list-div .list.blue-circle .perc::before{background: rgba(0, 121, 172, 1);}
.addingredients-modal .inner-card .list-div .list.skyblue-circle .perc::before{background:rgba(39, 242, 255, 1);}
.addingredients-modal .inner-card .list-div .list.light-circle .perc::before{background:rgba(230, 230, 230, 1);}
.addingredients-modal .inner-card .total-div{padding-top: 15px;border-top: 1px solid rgba(230, 230, 230, 1);margin-top: 15px;}
.addingredients-modal .inner-card .total-div .daily-value{font-size: 16px;line-height: 24px;font-weight: 600;color: rgba(13, 13, 13, 1);}
.addingredients-modal .inner-card .total-div .output-value{font-size: 16px;line-height: 24px;color: rgba(145, 145, 145, 1);font-weight: 500;padding-left: 10px;}
.addingredients-modal .tag-sec{height: 100%;overflow: hidden;display: flex;flex-direction: column;}
.addingredients-modal .tag-sec .tag-bg{background: rgba(239, 239, 239, 0.59);width: 100%;height: 100%;border-radius: 10px;}
.addingredients-modal .tag-sec .tag-div{border-radius: 10px;padding: 17px 10px 24px 10px;}
.addingredients-modal .tag-sec .tag-div{display: flex;gap: 9px;flex-wrap: wrap;}
.addingredients-modal .tag-sec .tag-div .tag{padding: 5px 10px 5px 10px;border-radius: 10px;background: rgba(116, 212, 115, 1);font-size: 12px;line-height: 14px;color: #fff;height: fit-content;}
.addingredients-modal .modal-content{padding: 24px;}
.add-recipe-inner .round{position: absolute;left: 0;top: 0;width: 100%;height: 100%;}
.add-recipe-inner .round label {background-color: transparent;border-radius: 0;cursor: pointer;height: 100%;left: 0;position: absolute;top: 0;width: 100%;}
.add-recipe-inner .round label::before{background-color: #fff;border: 1px solid rgba(116, 212, 115, 1);border-radius: 50%;cursor: pointer;height: 28px;left: 20px;position: absolute;top: 20px;width: 28px;content: '';}
.add-recipe-inner .round label::after {content: "";background-color: rgba(116, 212, 115, 1);border: 1px solid rgba(116, 212, 115, 1);border-radius: 50%;cursor: pointer;height: 18px;left: 25px;position: absolute;top: 25px;width: 18px;opacity: 0;}
.add-recipe-inner .round input[type="checkbox"] {visibility: hidden;}
.add-recipe-inner .round input[type="checkbox"]:checked + label::before {background-color: #fff;border-color: #66bb6a; }
.add-recipe-inner .round input[type="checkbox"]:checked + label:after { opacity: 1;}    
/* .css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root:focus{background-color: #77b720 !important;} */
.MuiButtonBase-root:focus{background-color: #77b720 !important;color: #fff;}
.nutrition-data-modal .modal-dialog{max-width: 85%;}


.meal-plan-item-action.dropdown button:after {
    display: none;
}
.meal-name-truncation{
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.MuiButtonBase-root.Mui-selected:focus{background-color: #1976d2 !important;color: #fff;}


.addingredients-modal .inner-card .list-div .list.light-salmon-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.light-salmon-circle .perc::before {background-color: rgba(255, 160, 122, 1) !important;}
.addingredients-modal .inner-card .list-div .list.lavender-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.lavender-circle .perc::before {background-color: rgba(230, 230, 250, 1) !important;}
.addingredients-modal .inner-card .list-div .list.gold-1-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.gold-1-circle .perc::before {background-color: rgba(255, 215, 0, 1) !important;}
.addingredients-modal .inner-card .list-div .list.yellow-1-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.yellow-1-circle .perc::before {background-color: rgba(255, 255, 0, 1) !important;}
.addingredients-modal .inner-card .list-div .list.dark-pink-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.dark-pink-circle .perc::before {background-color: rgba(204, 102, 255, 1) !important;}
.addingredients-modal .inner-card .list-div .list.blue-green-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.blue-green-circle .perc::before {background-color: rgba(128, 255, 223, 1) !important;}
.addingredients-modal .inner-card .list-div .list.powder-blue-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.powder-blue-circle .perc::before {background-color: rgba(176, 224, 230, 1) !important;}
.addingredients-modal .inner-card .list-div .list.nbg-orange-red-1-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.nbg-orange-red-1-circle .perc::before  {background-color: rgba(255, 69, 0, 1) !important;}
.addingredients-modal .inner-card .list-div .list.aqua-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.aqua-circle .perc::before {background-color: rgba(0, 170, 255, 1) !important;}
.addingredients-modal .inner-card .list-div .list.moderate-cyan-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.moderate-cyan-circle .perc::before {background-color: rgba(78, 205, 196, 1) !important;}
.addingredients-modal .inner-card .list-div .list.light-blue-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.light-blue-circle .perc::before {background-color: rgba(173, 216, 230, 1) !important;}
.addingredients-modal .inner-card .list-div .list.light-yellow-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.light-yellow-circle .perc::before {background-color: rgba(247, 239, 129, 1) !important;}
.addingredients-modal .inner-card .list-div .list.brown-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.brown-circle .perc::before {background-color: rgba(210, 105, 30, 1) !important;}
.addingredients-modal .inner-card .list-div .list.navy-blue-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.navy-blue-circle .perc::before {background-color: rgba(0, 0, 128, 1) !important;}
.addingredients-modal .inner-card .list-div .list.sky-blue-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.sky-blue-circle .perc::before {background-color: rgba(135, 206, 235, 1) !important;}
.addingredients-modal .inner-card .list-div .list.sliver-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.sliver-circle .perc::before {background-color: rgba(192, 192, 192, 1) !important;}
.addingredients-modal .inner-card .list-div .list.neon-light-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.neon-light-circle .perc::before {background-color: rgba(166, 253, 41, 1) !important;}
.addingredients-modal .inner-card .list-div .list.nasturtium-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.nasturtium-circle .perc::before {background-color: rgba(254, 94, 65, 1) !important;}
.addingredients-modal .inner-card .list-div .list.cadet-blue-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.cadet-blue-circle .perc::before {background-color: rgba(95, 158, 160, 1) !important;}
.addingredients-modal .inner-card .list-div .list.yellow-green-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.yellow-green-circle .perc::before {background-color: rgba(154, 205, 50, 1) !important;}
.addingredients-modal .inner-card .list-div .list.hot-pink-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.hot-pink-circle .perc::before {background-color: rgba(255, 105, 180, 1) !important;}
.addingredients-modal .inner-card .list-div .list.steel-blue-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.steel-blue-circle .perc::before {background-color: rgba(70, 130, 180, 1) !important;}
.addingredients-modal .inner-card .list-div .list.rosy-brown-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.rosy-brown-circle .perc::before {background-color: rgba(188, 143, 143, 1) !important;}
.addingredients-modal .inner-card .list-div .list.lime-blue-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.lime-blue-circle .perc::before {background-color: rgba(85, 255, 225, 1) !important;}
.addingredients-modal .inner-card .list-div .list.dark-orange-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.dark-orange-circle .perc::before {background-color: rgba(255, 140, 0, 1) !important;}
.addingredients-modal .inner-card .list-div .list.firebrick-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.firebrick-circle .perc::before {background-color: rgba(178, 34, 34, 1) !important;}
.addingredients-modal .inner-card .list-div .list.teal-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.teal-circle .perc::before {background-color: rgba(0, 128, 128, 1) !important;}
.addingredients-modal .inner-card .list-div .list.light-khaki-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.light-khaki-circle .perc::before {background-color: rgba(240, 230, 140, 1) !important;}
.addingredients-modal .inner-card .list-div .list.magenta-circle .perc::before,
.bottom-sec .nutrition-div .inner-card .list-div .list.magenta-circle .perc::before {background-color: rgba(255, 0, 255, 1) !important;}


.tab-add-sec{border: 1px dashed rgba(116, 212, 115, 0.41);padding: 16px;}
.tab-add-sec .tab-container{border: 1px dashed rgba(116, 212, 115, 0.41);padding: 25px;border-radius: 6px;}
.tab-w-close{position: relative;padding: 5px 10px 5px 10px;border-radius: 10px;background: rgba(116, 212, 115, 1);font-size: 12px;line-height: 14px;color: #fff;height: fit-content;width: fit-content;}
.tab-w-close .close{background: red;border-radius: 50%;border: 0;color: #fff;}

.append-select-div{position: relative;}
.custom-select-dropdown{width: 100%;background: #fff;position: absolute;top: 40px;left: 0;box-shadow: 0px 2px 4px 0 rgb(0 0 0 / 10%);    max-height:350px;overflow-y: scroll;}
.custom-select-dropdown ul{padding: 0;list-style: none;margin: 0;display: flex;flex-direction: column;width: 100%;border: 1px solid #ddd;border-top: 0;}
.custom-select-dropdown ul li{padding: 8px 16px;cursor: pointer;transition: all 0.3s ease-in-out;border-bottom: 1px solid #ddd;}
.custom-select-dropdown ul li:nth-last-child(1){border-bottom: 0;}
.custom-select-dropdown ul li:hover{background: #ddd;color: #000;}
.user-recipe-card{padding: 15px;padding-bottom: 0;}