.btn-filter, .btn-filter:hover {
    position: fixed;
    right: 50px;
    bottom: 50px;
    width: 80px;
    height: 80px;
    border: none;
    background-color: #FF9445;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
}
.recipe-card-header {
    .button-group{
        background-color: #FF9445;
        border: 1px solid #FF9445;
    }
}

.recipe-card-body {
    padding: 12px !important;
}

.recipe-card-body a {
    font-size: 18px !important;
}

.recipe-card-img {
    height: auto;
}

.single-v {
    grid-template-columns:repeat(3, 1fr);

    .recipe-box {
        flex-direction: unset;
    }

    .recipe-card-img {
        width: 40%;
        height: auto;

        & img {
            border-radius: 13px 0 0 13px;
        }

        .icon-div {
            height: 40px;
            width: 40px;
        }

        .heart-icon-svg {
            height: 22px;
            width: 22px;
        }
    }
}

.single-h {
    grid-template-columns:repeat(5, 1fr);
}

@media screen and (max-width: 1400px) {
    .single-h {
        grid-template-columns:repeat(4, 1fr);
    }
}

@media screen and (max-width: 1200px) {
    .single-h {
        grid-template-columns:repeat(3, 1fr);
    }
    .single-v {
        grid-template-columns:repeat(2, 1fr);
    }
}

@media screen and (max-width: 992px) {
    .main-content {
        overflow: unset;
    }

    .recipe-grid {
        gap: 15px;
    }

    .btn-filter, .btn-filter:hover {
        right: 20px;
        bottom: 20px;
        width: 70px;
        height: 70px;
    }

    .recipe-card-img {
        height: unset;

        .icon-div {
            height: 40px;
            width: 40px;
            right: 8px;
            top: 8px;
        }
    }

    .recipe-card-body {
        padding: 8px;
    }

    .recipe-card-body a {
        font-size: 16px !important;
    }

    .recipe-card-body p {
        font-size: 14px !important;
    }

    .recipe-card-body svg {
        width: 14px;
    }
}

@media screen and (max-width: 768px) {

    .recipe-card-title {
        font-size: 18px !important;
        border-left: 3px solid #FF9445;
        padding: 2px 0 0;
    }

    .recipe-card-header h5 {
        font-size: 18px !important;
        padding-left: .5rem !important;
    }

    .recipe-card-header p {
        font-size: 14px !important;
    }

    .single-h .recipe-card-img {
        height: 200px;
        top: 20%;

        .icon-div {
            height: 40px;
            width: 40px;
        }

        .heart-icon-svg {
            height: 22px;
            width: 22px;
        }
    }

    .double-h .recipe-card-img {
        height: unset;
        top: 20%;

        .icon-div {
            height: 40px;
            width: 40px;
        }

        .heart-icon-svg {
            height: 22px;
            width: 22px;
        }
    }

    .single-v {
        .recipe-box {
            flex-direction: unset;
        }

        .recipe-card-img {
            width: 30%;

            & img {
                border-radius: 13px 0 0 13px;
            }

            .icon-div {
                height: 40px;
                width: 40px;
            }

            .heart-icon-svg {
                height: 22px;
                width: 22px;
            }
        }
    }

    .btn-filter, .btn-filter:hover {
        position: fixed;
        right: 20px;
        bottom: 20px;
        width: 60px;
        height: 60px;
        border: none;
        background-color: #FF9445;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    }

    .btn-filter svg {
        height: 25px;
        width: 25px;
    }

    .single-h {
        grid-template-columns:repeat(2, 1fr);
    }

    .double-h {
        grid-template-columns:repeat(2, 1fr);
    }

    .single-v {
        grid-template-columns:repeat(1, 1fr);
    }
}
@media screen and (max-width: 540px){
    .single-h {
        grid-template-columns:repeat(1, 1fr);
    }
}